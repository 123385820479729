import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const HowToAttractWebsiteCustomers = ({ location }) => {
  const title =
    "Twitterを活用したホームページ集客術！インスタントウィン効果を無料ツールで最大化するには？〈活用例その3〉"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【無料ツール】Twitterからホームページ集客術！インスタントウィン効果を最大化するには？"
          pagedesc="会員登録やサイト集客目的のTwitterキャンペーンを、無料ツールを活用し業務コストを抑えて開催する方法を紹介。すぐにプレゼント企画に使えるよう、準備物やタスクリストも用意したSNSマーケ初心者必見の記事です。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-attract-website-customers/how-to-attract-website-customers.jpg"
          pagepath="/blog/how-to-attract-website-customers"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は4分で読むことができます
            </div>
            <h1>
              Twitterを活用したホームページ集客術！インスタントウィン効果を無料ツールで最大化するには？〈活用例その3〉
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年3月12日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="タブレット画面に表示されているログイン画面"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-attract-website-customers/how-to-attract-website-customers.jpg"
                className="w-full"
              />
            </figure>
            <h2>
              Twitterキャンペーンにツール導入は必要？使いこなせるかも不安…
            </h2>
            <p>
              より多くの企業アカウントにTwitterキャンペーンの効果を実感してほしいという思いから、導入費用ゼロのTwitterインスタントウィン抽選ツール「PARKLoT
              Lite」が登場しました！
            </p>
            <p>
              しかし、初めてTwitterキャンペーン開催する方や、今まで手動で実施してきた方にとっては、プレゼントキャンペーンを企画をすること、さらには有料ツールを導入することへ、懸念があるのではないでしょうか。
            </p>
            <h2>無料インスタントウィンでサイト集客・会員登録促進へ！</h2>
            <p>
              そこで、本記事ではTwitterキャンペーンに慣れていない方にもわかりやすいよう、ツールの使い方・開催例を丁寧に解説します。
            </p>
            <p>
              今回はPARKLoT
              Lite活用例・第3弾【ホームページ集客・会員登録サイト】に特化したキャンペーンアイデアです。
            </p>
            <p>
              うまくツールを活用することで、効率的にプレゼントキャンペーンを開催するだけではなく、自社ビジネスの目標にも効果が期待できます。特にハードルの高い、「サイト遷移からの会員登録」を促すような内容をご用意しました。
            </p>
            <p>貴社のSNSマーケティングの参考にしていただければ幸いです。</p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    無料でインスタントウィンを実施！PARKLoT Lite
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    簡単に開催可能！PARKLoT Liteのキャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    管理画面でできること
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    【会員登録・サイト集客】PARKLoT Lite利用のキャンペーン例
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    【会員登録・サイト集客】Twitterインスタントウィンの開催フローと準備物
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    インスタントウィンでTwitterからホームページ集客数を劇的にアップ！
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">無料でインスタントウィンを実施！PARKLoT Lite</h2>
              <p>
                一般的には高額な利用料がかかるインスタントウィンキャンペーンを、無料で開催できるのがPARKLoT
                Liteです。インスタントウィンの効果、ツール利用の便利さ、キャンペーン管理の効率化を実感いただける機能が揃っています。また、開催前の審査が必須で、セキュリティや個人情報についても、適切に運用しているので安心してご利用いただけます。
              </p>
              <p>
                PARKLoT Liteについて、
                <a
                  href="https://prtimes.jp/main/html/rd/p/000000016.000066114.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  プレスリリース
                </a>
                も公開されております。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/tips-for-sns-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【SNSキャンペーン】本気で販促につなげるなら！すぐできる10のポイント
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id2">簡単に開催可能！PARKLoT Liteのキャンペーン</h2>
              <p>
                PARKLoT
                Liteでは、キャンペーン投稿内のURLから参加者自身が結果確認するフォロー＆リツイートキャンペーンが開催できます。もちろん当選者はPARKLoT
                Liteのシステムで自動抽選されます。URLで抽選結果を確認するフローにすることで、DM送信上限を心配する必要が無くなりました。
              </p>
              <img
                placeholder="tracedSVG"
                alt="ParkLot Liteインスタントウィン参加者のフロー"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-attract-website-customers/how-to-attract-website-customers.jpg"
                className="w-full"
              />
              <div className="blog__border-highlight flex">
                <p>
                  <span className="emoji">&#x1F4A1;</span>
                </p>
                <ol>
                  <li>設定時間に自動でキャンペーンツイートが投稿されます</li>
                  <li>
                    ユーザーはフォロー＆リツイートだけで応募完了 （設定確率に合わせてPARKLoT
                    Liteが自動で抽選）
                  </li>
                  <li>
                    ユーザーはキャンペーンツイート内のURLからその場で結果確認！
                  </li>
                </ol>
              </div>
              <p>
                また、無料利用にあたって開催タイプ・景品数・開催回数に制限を設けています。
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>開催タイプ</td>
                    <td>
                      インスタントウィン（URLから結果確認のみ、DM通知なし）
                    </td>
                  </tr>
                  <tr>
                    <td>景品数</td>
                    <td>1種類のみ</td>
                  </tr>
                  <tr>
                    <td>開催回数</td>
                    <td>3回まで</td>
                  </tr>
                </tbody>
              </table>
              <p>
                無料回数消化後は初期費用無料、月額1万円（税別）で、引き続きご利用いただけます。応募者や当選者のデータも保持されますので、ご安心ください。
              </p>
            </div>
            <div>
              <h2 id="id3">管理画面でできること</h2>
              <p>
                PARKLoT
                Liteの管理画面は簡単に使いやすく、データも見やすい設計となっています。また、無料といっても簡単かつ効果的にTwitterキャンペーンを開催できるような機能を準備しています。
              </p>
              <table>
                <thead>
                  <tr>
                    <th className="blog__text-center">項目</th>
                    <th className="blog__text-center">可能な設定</th>
                    <th className="blog__text-center">便利な機能</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>基本情報</td>
                    <td>
                      ・キャンペーン名
                      <br />
                      ・開始日時
                      <br />
                      ・当選者のフィルタリング設定
                      <br />
                      など
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>ツイート</td>
                    <td>
                      ・ツイート文言
                      <br />
                      ・バナー画像
                    </td>
                    <td>実際の投稿画面を確認しながら入力できます。</td>
                  </tr>
                  <tr>
                    <td>プレゼント</td>
                    <td>
                      ・景品
                      <br />
                      ・当選者数
                      <br />
                      ・当選確率
                      <br />
                      ・住所やEメールアドレスの収集の有無
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>クリエイティブ</td>
                    <td>
                      ・当たり画面の文言と画像
                      <br />
                      ・ハズレ画面の文言と画像
                    </td>
                    <td>URLを記載し、サイトに誘導することができます。</td>
                  </tr>
                  <tr>
                    <td>応募者数</td>
                    <td>・キャンペーン開始後、応募者数とユーザーの確認</td>
                    <td>CSVでダウンロードすることも可能です。</td>
                  </tr>
                  <tr>
                    <td>当選者</td>
                    <td>・当選者数とユーザー、景品発送に伴う情報の確認</td>
                    <td>CSVでダウンロードすることも可能です。</td>
                  </tr>
                </tbody>
              </table>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="キャンペーン開催中の管理画面"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/campaign-for-ecsite/campaign-for-ecsite-3.png"
                maxwidth={640}
              />
              <small>（キャンペーン開催中の管理画面）</small>
            </div>
            <div>
              <h2 id="id4">
                【会員登録・サイト集客】PARKLoT Lite利用のキャンペーン例
              </h2>
              <p>
                架空の求人サイト「ナイスジョブ」での開催を想定してPARKLoT
                Liteを利用したキャンペーン例を紹介します。
              </p>
              <table>
                <tr>
                  <td className="font-bold">キャンペーン名</td>
                  <td>
                    アマギフが当たる！求人サイトナイスジョブ春の新生活キャンペーン！！
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">実施期間</td>
                  <td>7日間</td>
                </tr>
                <tr>
                  <td className="font-bold">景品</td>
                  <td>
                    ・参加者全員に資料URLをプレゼント（業界別：求人最新情報＆2024年度動向予測）
                    <br />
                    ・Wチャンスとして後日Amazonギフトカード1,000円分
                    <br />
                    ・さらに、ナイスジョブに会員登録した人から抽選でAmazonギフトカード5,000円分
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">当選者数</td>
                  <td>
                    ・資料URL 1,000名
                    <br />
                    ・Amazonギフトカード1,000円分×1名
                    <br />
                    ・5,000円分×1名
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">応募方法</td>
                  <td>フォロー＆リツイート</td>
                </tr>
                <tr>
                  <td className="font-bold">参加方法</td>
                  <td>
                    1.ナイスジョブの公式アカウントをフォロー
                    <br />
                    2.対象ツイートをリツイート
                    <br />
                    （3. ナイスジョブサイトで会員登録）
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">結果通知方法</td>
                  <td>
                    ・対象ツイート内のURLから参加者が確認
                    <br />
                    ・Wチャンスは当選者のみDM送付
                    <br />
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">結果通知タイミング</td>
                  <td>
                    ・即時（インスタントウィン）
                    <br />
                    ・後日通知（Wチャンス、会員登録応募）
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">意図</td>
                  <td>
                    ・春の新生活に合わせたキャンペーンです。ナイスジョブ公式Twitterアカウントの認知拡大と、ナイスジョブWebページへの流入増加、さらに会員登録までを促します。
                    <br />
                    ・求職者への有益な資料を景品にすることで、サイトのターゲット層に「良質な情報源」としてグッドジョブアカウントの認知拡大を狙います。
                    <br />
                    ・後日抽選のAmazonギフトカードを景品にすることで、求職状態を問わずTwitter上で大きく拡散してもらえるように設計しています。
                    <br />
                    ・グッドジョブサイトでの会員登録にもインセンティブを設定し、ターゲット層の利用を促進します。
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">効果</td>
                  <td>
                    ・応募はフォロー＆リツイートだけと簡単なので、高い参加率が期待できます。
                    <br />
                    ・サイト名、サービス内容の宣伝がTwitter上で拡散されます。バナーや対象ツイート内に会員登録でプレゼントの紹介をのせるとさらに効果的です。
                    <br />
                    ・キャンペーンをきっかけにフォローしたユーザーに対して、ダイレクトに情報発信ができます。また、将来のターゲット（求職者）として想起してもらえる可能性も高まります。
                    <br />
                    ・当選確率を1/1にしておき、参加者全員プレゼントにすることで、アカウントに対してポジティブな印象を与えることができます。
                    <br />
                    ・結果メッセージ欄にサイトURLリンクを貼ることで、応募から時間をおかずにWebページへの遷移を促します。
                  </td>
                </tr>
              </table>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="当選確率を1/1にすれば応募者全員にプレゼントも可能です。当選確率と当選数はキャンペーン中も変更できます。"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/campaign-for-ecsite/campaign-for-ecsite-4.png"
                maxwidth={640}
              />
              <small>
                （当選確率を1/1にすれば応募者全員にプレゼントも可能です。当選確率と当選数はキャンペーン中も変更できます。）
              </small>
            </div>
            <div>
              <h2 id="id5">
                【会員登録・サイト集客】Twitterインスタントウィンの開催フローと準備物
              </h2>
              <p>
                続いて、架空の求人サイト「ナイスジョブ」でのキャンペーン開催に関して、フローと準備物を説明します。
              </p>
              <table>
                <thead>
                  <tr>
                    <th className="blog__text-center">スケジュール</th>
                    <th className="blog__text-center">準備タスク</th>
                    <th className="blog__text-center">準備物</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>〜開始1週間前</td>
                    <td>
                      Twitterでナイスジョブアカウントにログインした状態で、
                      <a
                        href="https://promoter.park-lot.com/signup"
                        target="_blank"
                        rel="noreferrer"
                      >
                        PARKLoT Lite
                      </a>
                      に無料アカウント登録をする
                      <br />
                      その後、管理画面で新規キャンペーンを作成する
                    </td>
                    <td>
                      ①ツイート文
                      <br />
                      ②バナークリエイティブ
                      <br />
                      ③限定資料をアップロードしたURL
                    </td>
                  </tr>
                </tbody>
                <tr>
                  <td></td>
                  <td>（PARKLoTによるキャンペーン審査）</td>
                  <td></td>
                </tr>
                <tr>
                  <td>キャンペーン開始日</td>
                  <td>
                    （自動でツイートが投稿されるので、タスクはありません）
                    <br />
                    （自動抽選で、参加者がURLをタップすると結果画面が即時表示されます）
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>終了後〜</td>
                  <td>
                    PARKLoT
                    Lite管理画面で参加者一覧を確認し、Amazonギフトカード当選者にDMをする
                  </td>
                  <td>④Amazonギフトコード</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    ナイスジョブサイトへの会員登録者を確認し、
                    Amazonギフトカードを当選者に送付する
                  </td>
                  <td>⑤Amazonギフトコード</td>
                </tr>
              </table>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="（参加者一覧、当選者一覧のcsv出力が可能です。）"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/campaign-for-ecsite/campaign-for-ecsite-5.png"
                maxwidth={640}
              />
              <small>（参加者一覧、当選者一覧のcsv出力が可能です。）</small>
            </div>
            <div>
              <h2 id="id6">
                インスタントウィンでTwitterからホームページ集客数を劇的にアップ！
              </h2>
              <p>
                Twitterキャンペーンの中でも最も人気なキャンペーンは、フォロー＆リツイートです。また、インスタントウィンであれば、参加者のモチベーションが高い状態でホームページやサイト会員登録へ誘導しやすくなります。コストや手間を最小限に抑えて、まずはキャンペーンをやってみたいとお考えであれば、PARKLoT
                Liteは最適です。ぜひとも気軽にお試しください。
              </p>
              <p>
                貴社のTwitterアカウントにログインした状態で、
                <a
                  href="https://promoter.park-lot.com/signup"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://promoter.park-lot.com/signup
                </a>
                からご利用ください。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/2021-campaign-result"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンの成功の秘訣は？PARKLoT公式アカウントのキャンペーン結果を大公開！！
                  </a>
                </p>
              </div>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="kakefuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/tips-for-sns-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【SNSキャンペーン】本気で販促につなげるなら！すぐできる10のポイント
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-instant-win-campaign-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.1
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-instant-win-campaign-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.2
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/banner-request"
                    target="_blank"
                    rel="noreferrer"
                  >
                    バナー依頼時にデザイナーがマーケターに気をつけて欲しい５つのポイント
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-get-followers"
                    target="_blank"
                    rel="noreferrer"
                  >
                    一気に増やす！Twitterのフォロワー獲得方法【注意点も解説】
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default HowToAttractWebsiteCustomers
